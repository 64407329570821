.questionText {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem; }

.answers {
  list-style: none;
  margin: 0;
  padding: 0; }

.answer:hover {
  background: #e9e9e9; }
