.score {
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  left: 0;
  padding: 0.5rem 1rem;
  position: fixed;
  width: 100%; }

.label {
  font-weight: 900;
  margin-right: 1rem;
  text-transform: uppercase; }

.rounds {
  margin-right: 1rem; }
