@font-face {
  font-family: 'Source Sans Variable';
  src: url(./fonts/source-sans/source-sans-variable.woff2) format("woff2" supports variations);
  src: url(./fonts/source-sans/source-sans-variable.woff2) format("woff2");
  font-weight: 1 999;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Variable';
  src: url(./fonts/source-sans/source-sans-variable-italic.woff2) format("woff2" supports variations);
  src: url(./fonts/source-sans/source-sans-variable-italic.woff2) format("woff2");
  font-weight: 1 999;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans';
  src: url(./fonts/source-sans/source-sans.woff2) format("woff2"), url(./fonts/source-sans/source-sans.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans';
  src: url(./fonts/source-sans/source-sans-bold.woff2) format("woff2"), url(./fonts/source-sans/source-sans-bold.woff) format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans';
  src: url(./fonts/source-sans/source-sans-italic.woff2) format("woff2"), url(./fonts/source-sans/source-sans-italic.woff) format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans';
  src: url(./fonts/source-sans/source-sans-bold-italic.woff2) format("woff2"), url(./fonts/source-sans/source-sans-bold-italic.woff) format("woff");
  font-weight: bold;
  font-style: italic; }

html {
  font-family: 'Source Sans', 'Seravek', 'Segoe UI', 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, sans-serif; }
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Source Sans Variable', 'Seravek', 'Segoe UI', 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, sans-serif; } }

body {
  margin: 0;
  padding: 0; }
