.header {
  background: #7b00fd;
  color: white;
  padding: 0.25rem;
  line-height: 1; }

.pageTitle {
  font-size: 1rem;
  font-weight: 900;
  margin: 0;
  text-align: center;
  text-transform: uppercase; }
