.header {
  align-items: center;
  color: #777777;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5rem; }

.title {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0.5rem 0; }
